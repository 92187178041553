import './app.scss';

function App() {
  return (
    <div className="app">
      <div className="landing-image"></div>
      <h1>Jenko Rossi</h1>
      <p>
        Software developer / artist / purveyor of NFTs
        <br /><br />
        Instagram <a href="https://www.instagram.com/jenkorossi/" target="_blank">@jenkorossi</a>
        <br />
        Twitter <a href="https://twitter.com/jenkorossi" target="_blank">@jenkorossi</a>
        <br />
        Foundation <a href="https://foundation.app/jenkorossi" target="_blank">@jenkorossi</a>
      </p>
    </div>
  );
}

export default App;
